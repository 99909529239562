import React, { useRef, useMemo, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./Space.css"; // CSS animations

// **Random Color Generator for Stars**
const getRandomColor = () => {
  const colors = ["#FFD700", "#ADD8E6", "#9400D3", "#FF4500", "#00FFFF"]; // Yellow, Blue, Purple, Orange, Cyan
  return colors[Math.floor(Math.random() * colors.length)];
};

// **Star Component (Colored Stars Moving Forward)**
const Star = ({ position }) => {
  const ref = useRef();
  const color = useMemo(getRandomColor, []);

  useFrame(() => {
    if (ref.current) {
      ref.current.position.z += 0.2; // Move forward

      // Reset when moving past view
      if (ref.current.position.z > 5) {
        ref.current.position.z = -50;
        ref.current.position.x = Math.random() * 20 - 10;
        ref.current.position.y = Math.random() * 20 - 10;
      }
    }
  });

  return (
    <mesh ref={ref} position={position}>
      <sphereGeometry args={[0.1, 8, 8]} />
      <meshBasicMaterial color={color} />
    </mesh>
  );
};

// **Starfield Component (Generates Multiple Stars)**
const Stars = () => {
  const stars = useMemo(() => {
    return [...Array(150)].map(() => [
      Math.random() * 20 - 10, // X
      Math.random() * 20 - 10, // Y
      Math.random() * -50, // Z (depth)
    ]);
  }, []);

  return (
    <>
      {stars.map((position, i) => (
        <Star key={i} position={position} />
      ))}
    </>
  );
};

// **Fish Emoji Component (Swims with CSS Animation)**
const FishEmoji = ({ style }) => {
  return (
    <span className="fish-emoji" style={style}>
      🐟
    </span>
  );
};

// **Main Space Scene with Stars & Floating Fish Emojis**
const Space = () => {
  const navigate = useNavigate();
  const [fadeOut, setFadeOut] = useState(false);
  const [fishPositions, setFishPositions] = useState([]);

  useEffect(() => {
    setFishPositions(
      Array.from({ length: 10 }).map(() => ({
        left: Math.random() * 100 + "vw",
        top: Math.random() * 100 + "vh",
        animationDuration: Math.random() * 3 + 4 + "s",
      }))
    );

    // Trigger fade-out before navigating
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => navigate("/miles"), 1000);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <AnimatePresence>
      {!fadeOut && (
        <motion.div
          className="space-container"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          {/* Restored Rainbow Stars in Canvas */}
          <Canvas className="canvas">
            <Stars />
          </Canvas>

          {/* Floating Fish Emojis */}
          {fishPositions.map((pos, index) => (
            <FishEmoji key={index} style={pos} />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Space;
