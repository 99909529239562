import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";
import "assets/css/miles.css";

import Index from "views/Index.jsx";
import Miles from "views/Miles.jsx";
import Space from "views/Space.jsx";
import Electric from "views/Electric.jsx";
import MMedia from "views/examples/MMedia.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Electric />} />
      <Route path="/home" element={<Index />} />
      <Route path="/space" element={<Space />} />
      <Route path="/miles" element={<Miles />} />

      <Route path="/mileselectric-media" element={<MMedia />} />

      {/* Redirect all unknown routes to Home */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
