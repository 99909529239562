import React, { useRef, useMemo, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import centerLogo from "assets/img/brand/center.png";
import "./SpaceAnimation.css";

const Star = ({ position }) => {
  const ref = useRef();

  useFrame(() => {
    if (ref.current) {
      ref.current.position.z += 0.2;

      if (ref.current.position.z > 5) {
        ref.current.position.z = -50;
        ref.current.position.x = Math.random() * 20 - 10;
        ref.current.position.y = Math.random() * 20 - 10;
      }
    }
  });

  return (
    <mesh ref={ref} position={position}>
      <sphereGeometry args={[0.1, 8, 8]} />
      <meshBasicMaterial color="white" />
    </mesh>
  );
};

const Stars = () => {
  const stars = useMemo(() => {
    return [...Array(200)].map(() => [
      Math.random() * 20 - 10,
      Math.random() * 20 - 10,
      Math.random() * -50,
    ]);
  }, []);

  return (
    <>
      {stars.map((position, i) => (
        <Star key={i} position={position} />
      ))}
    </>
  );
};

const Electric = () => {
  const navigate = useNavigate();
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => navigate("/home"), 1000);
    }, 9000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <AnimatePresence>
      {!fadeOut && (
        <motion.div
          className="space-container"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <Canvas className="canvas">
            <Stars />
          </Canvas>

          {/* Clickable Animated Logo */}
          <a href="/home" className="center-logo-link">
            <motion.img
              src={centerLogo}
              alt="Center Logo"
              className="center-logo"
              style={{ pointerEvents: "auto" }} // Ensure it's clickable
              animate={{
                y: ["0px", "15px", "0px"],
                rotate: ["0deg", "5deg", "-5deg", "0deg"],
                scale: [1, 1.05, 1],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              whileHover={{ scale: 1.1 }} // Slight zoom effect on hover
            />
          </a>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Electric;
