/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class MilesNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img alt="..." src={require("assets/img/brand/header.png")} />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/header.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-tablet-button d-lg-none mr-1" />
                      <span className="nav-link-inner--text">📺</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href="https://www.electricfish.xyz"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                            <img
                              alt="..."
                              style={{ height: 50, width: 50 }}
                              src={require("assets/img/brand/ef.png")}
                            />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Electric Fish
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              When a rebellious young hacker seeks revenge on a
                              corrupt bank, he crosses paths with a formidable
                              hacking group and becomes the biggest threat
                              against their attempt to steal one billion
                              dollars.
                            </p>
                          </Media>
                        </Media>

                        <Media
                          className="d-flex align-items-center"
                          href="https://writers.coverfly.com/projects/view/d4027fec-8c2b-4569-8421-3627a608702e/Are_You_Fing_Kidding_Me"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                            <img
                              alt="..."
                              style={{ height: 50, width: 50 }}
                              src={require("assets/img/brand/ayfkm_s.png")}
                            />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Are you f***ing kidding me?
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              After a virus of alien origin turns the world into
                              infectious undead, an antisocial millennial,
                              plagued by her aversion to human contact, embarks
                              on a journey to save humanity from impending
                              extinction.
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/_mileselectric"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      "Opinions are probably our own"
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default MilesNavbar;
